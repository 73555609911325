@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.row {
  padding: $main-spacing 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @include gt-xs {
    align-items: center;
    flex-direction: row;
  }

  &:nth-of-type(2n+1){
    //background-color: darken($color-background, 5%);
  }
  &:nth-of-type(2n){
    //background-color: darken($color-background, 10%);
  }

  .row-label {
    font-weight: 300;
    width: 100%;

    @include gt-sm {
      width: 25%;
    }
  }

  .row-content {
    font-size: 1.1rem;
    font-weight: 700;
  }
}
